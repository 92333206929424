/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';

import {
  NoAccess,
  MoreButton,
  PermissionsGate,
  IconButtonV2,
} from '@components';
import RefreshIcon from '@mui/icons-material/Refresh';

import styles from './pageListLayout.style';

const PageListLayout = ({
  renderHeaderOptions,
  children,
  entity,
  disablePermissionGate,
  title,
  count,
  actions,
  onRefresh,
}) => {
  return (
    <PermissionsGate
      entity={entity}
      RenderError={NoAccess}
      disabled={disablePermissionGate}
    >
      <Box sx={styles.wrapper}>
        <Box sx={styles.header}>
          <Box sx={styles.title}>
            {title} {count >= 0 && <Box sx={styles.count}>({count})</Box>}{' '}
            {onRefresh && (
              <IconButtonV2 variant="text" color="blues" onClick={onRefresh}>
                <RefreshIcon />
              </IconButtonV2>
            )}
          </Box>
          <Box sx={styles.headerOptions}>
            <MoreButton moreOptions={actions} />
            {renderHeaderOptions && renderHeaderOptions()}
          </Box>
        </Box>

        <Box>{children}</Box>
      </Box>
    </PermissionsGate>
  );
};

export default PageListLayout;
