/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import {
  IconButton,
  Breadcrumps,
  NoAccess,
  MoreButton,
  Logo,
  PermissionsGate,
} from '@components';

const PageContentLayout = ({
  onRefresh,
  headerOptions,
  children,
  withBackButton,
  headerStyle,
  headerContainerStyle,
  links,
  noHeader,
  entity,
  disablePermissionGate,
  width = '100%',
  isSticky = true,
  moreOptions,
  total,
  isLogin,
  noHorizontalPadding = true,
  wrapperWidth = '100%',
  noPadding,
  styles = {},
  newRefresh,
}) => {
  const router = useRouter();
  const _refresh = async () => {
    onRefresh();
  };

  const _goBack = () => {
    router.back();
  };

  return (
    <PermissionsGate
      entity={entity}
      RenderError={NoAccess}
      disabled={disablePermissionGate}
    >
      <Box
        sx={{
          display: 'flex',
          width: 'auto',
        }}
      >
        <Box sx={{ width: wrapperWidth }}>
          {!noHeader && (
            <Box
              sx={
                headerContainerStyle || {
                  position: isSticky ? 'sticky' : 'static',
                  top: isLogin ? 0 : '40px',
                  zIndex: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width,
                  borderBottom: (theme) =>
                    `1px solid ${theme.palette.separator}`,
                  padding: isLogin ? '0 16px' : '16px',
                  paddingLeft: withBackButton ? 0 : '16px',
                  backgroundColor: (theme) =>
                    isLogin
                      ? theme.palette.lightGray.main
                      : theme.palette.white.paper,
                  height: isLogin ? '40px' : '69px',
                }
              }
            >
              {isLogin && (
                <Box sx={{ height: '100%' }}>
                  <Logo />
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {withBackButton && (
                  <IconButton
                    sx={{
                      marginLeft: '10px',
                    }}
                    color="black"
                    onClick={_goBack}
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                  </IconButton>
                )}
                <Breadcrumps links={links} />
                <Box sx={{ width: '4px' }} />
                {total >= 0 && (
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      marginTop: '-2px',
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >{`(${total})`}</Typography>
                )}
                {onRefresh && (
                  <IconButton
                    onClick={_refresh}
                    color={newRefresh ? 'blues' : 'primary'}
                    sx={{
                      marginLeft: '4px',
                      padding: '4px',
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                )}
              </Box>
              <Box
                sx={
                  headerStyle || {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }
                }
              >
                <MoreButton moreOptions={moreOptions} />
                {headerOptions && headerOptions()}
              </Box>
            </Box>
          )}

          <Box
            sx={{
              padding: noPadding
                ? '0'
                : noHorizontalPadding
                ? '15px 0'
                : '15px',
              paddingTop: '19px',
              ...styles,
            }}
          >
            <Box>{children !== null ? children : null}</Box>
          </Box>
        </Box>
      </Box>
    </PermissionsGate>
  );
};

const memoizedPageContentLayout = React.memo(PageContentLayout);
export { memoizedPageContentLayout as PageContentLayout };
