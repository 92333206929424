export default {
  wrapper: { padding: '0 20px 0px 20px' },
  header: {
    position: 'sticky',
    top: '40px',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    justifyContent: 'space-between',
    backgroundColor: (theme) => theme.palette.white.paper,
  },
  headerOptions: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '1.25rem',
    color: (theme) => theme.palette.grays.gray700,
    fontWeight: 700,
  },
  count: {
    color: (theme) => theme.palette.grays.gray500,
    fontSize: '1rem',
    fontWeight: 400,
  },
};
