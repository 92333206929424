/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useRouter } from 'next/router';

import { Loader, NoAccess, PermissionsGate, TaskStages } from '@components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import styles from './taskLayout.style';

const TaskLayout = ({
  renderHeaderOptions,
  children,
  entity,
  task,
  url,
  isLoading,
  stages,
  currentStage,
  onChangeStage,
}) => {
  const router = useRouter();

  const handleBackButton = () => {
    if (url) {
      router.push(url);
    } else {
      router.back();
    }
  };

  return (
    <PermissionsGate entity={entity} RenderError={NoAccess}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.wrapper}>
          <Box sx={styles.innerWrapper}>
            <Box sx={styles.header}>
              <Box sx={styles.headerInner}>
                <Box sx={styles.breadcrump}>
                  <IconButton onClick={handleBackButton} sx={styles.backBtn}>
                    <ArrowBackIosIcon sx={styles.icon} />
                  </IconButton>
                  <Box sx={styles.task}>{task}</Box>
                </Box>
                <Box>{renderHeaderOptions?.()}</Box>
              </Box>
              {stages && (
                <TaskStages
                  stages={stages}
                  currentStage={currentStage}
                  onChange={onChangeStage}
                />
              )}
            </Box>

            <Box sx={styles.content}>{children}</Box>
          </Box>
        </Box>
      )}
    </PermissionsGate>
  );
};

export default TaskLayout;
