import theme from 'config/theme';

export default {
  wrapper: { padding: '0 20px', background: theme.palette.blues.lightestBlue },
  innerWrapper: {
    margin: '0 auto',
    maxWidth: theme.customValues.maxTaskPageWidth,
  },
  header: {
    marginBottom: '12px',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fff',
    padding: '0.75rem',
    marginBottom: '5px',
  },
  breadcrump: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    background: theme.palette.blues.primaryBlue100,
    '&:hover': {
      background: theme.palette.blues.primaryBlue100,
    },
  },
  icon: {
    fontSize: '.9rem',
    color: theme.palette.blues.primaryBlue700,
    width: '14px',
    position: 'absolute',
    left: '6px',
  },
  task: {
    fontSize: '0.875rem',
    color: theme.palette.grays.gray700,
  },
};
